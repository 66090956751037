import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
          <div className="columns">
              <div className="column"/>
              <div className="column is-three-fifths">
                  <div className="content">
                      <h1>Thank you!</h1>
                      <p>We will be in touch shortly</p>
                  </div>
              </div>
              <div className="column"/>
          </div>
      </div>
    </section>
  </Layout>
)
